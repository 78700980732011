
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	let debounceTimer = null

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				variations: [],
				requiredModifiers: []
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			cart () {
				return this.$store.state.cart
			},
			favorites () {
				return this.$store.state.favorites
			},
			selectedItem () {
				return this.$store.state.selectedItem
			},
			weighingScale () {
				return this.$store.state.weighingScale
			},
			isWeighingScaleEnabled () {
				return this.weighingScale.enabled && this.weighingScale.serialPortOptions.path !== null
			},
			currencySymbol () {
				return this.merchant.customAttributes?.currency_symbol || this.$currency.getCurrencySymbol()
			},
			settings () {
				return this.$store.state.settings
			},
			selectedOrder () {
				return this.$store.state.selectedOrder
			},
			kotCancellationReason () {
				return this.$store.state.kotCancellationReason
			},
			kotCancellationModal: {
				get () {
					return this.$store.state.kotCancellationModal
				},
				set (value) {
					this.$store.commit('setState', { key: 'kotCancellationModal', value })
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			cart (cart) {
				this.variations.forEach((variation) => {
					const cartItemIndex = this.filterItem(cart.items, variation, 'index')

					if (variation.cart_index > -1 && cartItemIndex > -1 && variation.cart_index !== cartItemIndex) {
						variation.cart_index = cartItemIndex
					}

					variation.quantity = cart.items[cartItemIndex]?.quantity || 0
				})
			}
		},
		beforeMount () {
			this.variations = this.selectedItem.variations.map((v) => {
				const cartItemVariation = this.filterItem(this.cart.items, v)

				if (!cartItemVariation) {
					v.modifiers.reduce((requiredModifiers, modifier) => {
						if (modifier.is_required) {
							requiredModifiers.push(`${modifier.id}-${modifier.name.toLowerCase()}`)
						}

						return requiredModifiers
					}, this.requiredModifiers)
				}

				return {
					...v,
					quantity: cartItemVariation ? cartItemVariation.quantity : (v.quantity || 0),
					total_price: cartItemVariation?.total_price || this.$currency.transformNumber(v.price * v.quantity)
				}
			})
		},
		mounted () {
			setTimeout(() => {
				this.requiredModifiers.forEach((modifierName) => {
					const input = document.querySelector(`input[name="${modifierName}"]`)

					if (input && !input.checked) {
						input.click()
					}
				})
			})
		},
		methods: {
			initItemModel (index) {
				const item = Object.assign({}, this.selectedItem)

				this.removeSelectedItem()
				item.show = true
				item.variations = !isNaN(index) ? [item.variations[index]] : []
				this.$store.commit('setState', {
					key: 'item',
					value: item
				})
			},
			removeSelectedItem () {
				this.$store.commit('setState', {
					key: 'selectedItem',
					value: { show: false }
				})
			},
			selectBatch (variation, $event) {
				const batch = variation.batch.find(b => (b.batch_id || b.id) === $event)

				if (batch) {
					variation.batch_id = batch.batch_id || batch.id
					variation.price = batch.price
					variation.custom_attributes.mrp = batch.mrp

					if (!isNaN(variation.cart_index)) {
						const cartItemVariation = this.cart.items[variation.cart_index]

						if (cartItemVariation) {
							cartItemVariation.batch_id = variation.batch_id
							cartItemVariation.price = variation.price
							cartItemVariation.mrp = variation.custom_attributes.mrp
							this.$store.dispatch('cartCalculation')
						}
					} else {
						const cartItemVariation = this.filterItem(this.cart.items, variation)

						variation.quantity = cartItemVariation ? cartItemVariation.quantity : 0
						variation.total_price = cartItemVariation?.total_price || this.$currency.transformNumber(variation.price * variation.quantity)
					}
				}
			},
			useWeighingScale (variation) {
				this.getWeight((weight) => {
					if (weight) {
						variation.quantity = weight
						this.$store.dispatch('modifyCart', {
							item: {
								id: this.selectedItem.id,
								name: this.selectedItem.name,
								category_id: this.selectedItem.category ? this.selectedItem.category.id : null,
								brand_id: this.selectedItem.brand?.id || null
							},
							variation
						})
					} else {
						this.$swal({
							title: this.$t('weighingScaleError.title'),
							text: this.$t('weighingScaleError.text'),
							icon: 'error',
							button: this.$t('ok')
						})
					}
				})
			},
			onChangePrice (variation, index) {
				let cartItemVariation = null

				if (!isNaN(variation.cart_index)) {
					cartItemVariation = this.cart.items[variation.cart_index]
					this.validateVariation(index).then((isValid) => {
						if (isValid) {
							cartItemVariation.price = variation.price
							cartItemVariation.total_price = this.$currency.transformNumber(variation.price * variation.quantity)
							this.$store.dispatch('cartCalculation')
						}
					})
				} else {
					cartItemVariation = this.filterItem(this.cart.items, variation)
					variation.quantity = cartItemVariation ? cartItemVariation.quantity : 0
				}

				variation.total_price = this.$currency.transformNumber(variation.price * variation.quantity)
			},
			onChangeQty (variation, type, index) {
				variation.quantity = variation.quantity || 0
				this.validateVariation(index).then((isValid) => {
					if (isValid) {
						const cartItemIndex = this.filterItem(this.cart.items, variation, 'index')

						if (type === '+') {
							variation.quantity = (this.cart.items[cartItemIndex]?.quantity || 0) + 1
						} else if (type === '-') {
							variation.quantity = (this.cart.items[cartItemIndex]?.quantity || 0) - 1
						} else if (cartItemIndex > -1) {
							this.cart.items.splice(cartItemIndex, 1)
						}

						variation.quantity = parseFloat(variation.quantity.toFixed(
							this.getUnitDecimalPlaces(variation.unit_measure_type)
						))

						if (variation.quantity < 0) {
							variation.quantity = 0
						} else if (variation.quantity > 9999) {
							variation.quantity = 9999
						}

						variation.total_price = this.$currency.transformNumber(variation.price * variation.quantity)
						this.$store.dispatch('modifyCart', {
							item: {
								id: this.selectedItem.id,
								name: this.selectedItem.name,
								category_id: this.selectedItem.category ? this.selectedItem.category.id : null,
								brand_id: this.selectedItem.brand?.id || null
							},
							variation
						})
					}
				})
			},
			async validateVariation (index) {
				const validators = [this.$refs[`quantity-${index}`][0].validate()]

				if (this.$refs[`batch-${index}`]) {
					validators.push(this.$refs[`batch-${index}`][0].validate())
				}

				if (this.$refs[`price-${index}`]) {
					validators.push(this.$refs[`price-${index}`][0].validate())
				}

				return (await Promise.all(validators)).every(validator => validator.valid)
			},
			onChangeBuyingPrice (variation, $event) {
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(() => {
					const buyingPrice = +$event || 0

					variation.total_price = buyingPrice
					variation.quantity = +this.toFixed(buyingPrice / variation.price, this.getUnitDecimalPlaces(variation.unit_measure_type))
					this.$store.dispatch('modifyCart', {
						item: {
							id: this.selectedItem.id,
							name: this.selectedItem.name,
							category_id: this.selectedItem.category ? this.selectedItem.category.id : null,
							brand_id: this.selectedItem.brand?.id || null
						},
						variation
					})
				}, 500)
			},
			getGroupIndex (variation, modifierId, mVariationId) {
				return Array.isArray(variation.groups)
					? variation.groups.findIndex((m) => {
						return m.item_variation_group_id === modifierId &&
							m.group_item_variation_id === mVariationId
					})
					: -1
			},
			updateModifiers (type, variation, modifier, mVariation, $event) {
				this.$set(variation, 'groups', variation.groups || [])

				if (type === 'checkbox') {
					if ($event) {
						variation.price += mVariation.price
						variation.groups.push({
							item_variation_group_id: modifier.id,
							group_item_variation_id: mVariation.id,
							item_variation_name: mVariation.name,
							alternate_name: mVariation.custom_attributes.alternate_language,
							type: 'modifier',
							price: mVariation.price,
							quantity: 1
						})
					} else {
						const index = variation.groups.findIndex(
							m => m.item_variation_group_id === modifier.id && m.group_item_variation_id === mVariation.id
						)

						if (index > -1) {
							variation.price -= variation.groups[index].price
							variation.groups.splice(index, 1)
						}
					}
				} else if (type === 'radio') {
					modifier.variations.forEach((v) => {
						const index = variation.groups.findIndex(m => m.group_item_variation_id === v.id)

						if (index > -1) {
							variation.price -= variation.groups[index].price
							variation.groups.splice(index, 1)
						}
					})

					variation.price += mVariation.price
					variation.groups.push({
						item_variation_group_id: modifier.id,
						group_item_variation_id: mVariation.id,
						item_variation_name: mVariation.name,
						alternate_name: mVariation.custom_attributes.alternate_language,
						type: 'modifier',
						price: mVariation.price,
						quantity: 1
					})
				}

				variation.total_price = this.$currency.transformNumber(variation.price * variation.quantity)

				if (this.cart.items[variation.cart_index]) {
					this.cart.items[variation.cart_index].price = variation.price
					this.cart.items[variation.cart_index].groups = variation.groups
					this.$store.dispatch('cartCalculation')
				} else {
					const cartItemVariation = this.filterItem(this.cart.items, variation)

					variation.quantity = cartItemVariation ? cartItemVariation.quantity : 0
				}
			},
			updateModifierQuantity ($event, variation, modifier, mVariation) {
				const index = this.getGroupIndex(variation, modifier.id, mVariation.id)

				if (index > -1) {
					variation.price -= variation.groups[index].price
					variation.groups[index].quantity = $event
					variation.groups[index].price = mVariation.price * $event
					variation.price += variation.groups[index].price
					this.updateModifiers(null, variation)
				}
			},
			validateModifier (variation, modifier, mVariation, type) {
				const maxSelectable = variation.custom_attributes.group_conditions?.max_selectable || 0
				const maxOptionsQuantity = modifier.custom_attributes.max_quantity || 0
				const maxOptionsSelectable = modifier.is_multi_choice ? modifier.custom_attributes.max_selectable : 0
				const uniqModifiers = variation.groups
					? variation.groups.reduce((modifiers, group) => {
						if (group.type === 'modifier') {
							if (modifiers[group.item_variation_group_id]) {
								modifiers[group.item_variation_group_id].totalQuantity += group.quantity
								modifiers[group.item_variation_group_id].options.push(group.group_item_variation_id)
							} else {
								modifiers[group.item_variation_group_id] = {
									totalQuantity: group.quantity,
									options: [group.group_item_variation_id]
								}
							}
						}

						return modifiers
					}, {})
					: {}

				return (
					!uniqModifiers[modifier.id] &&
					maxSelectable > 0 &&
					Object.keys(uniqModifiers).length >= maxSelectable
				) || (
					uniqModifiers[modifier.id] &&
					(
						(
							maxOptionsQuantity > 0 &&
							uniqModifiers[modifier.id].totalQuantity >= maxOptionsQuantity) ||
						(
							type === 'option' &&
							maxOptionsSelectable > 0 &&
							uniqModifiers[modifier.id].options.length >= maxOptionsSelectable
						)
					) && uniqModifiers[modifier.id].options.includes(mVariation.id) === (type === 'quantity')
				)
			},
			resetModifiers (variation, modifier) {
				if (!Array.isArray(variation.groups)) {
					variation.groups = []
				}

				if (modifier) {
					variation.groups = variation.groups.filter((g) => {
						const flag = g.item_variation_group_id !== modifier.id

						if (!flag) {
							variation.price -= g.price
						}

						return flag
					})
				} else {
					const totalPrice = variation.groups.reduce((sum, g) => {
						sum += g.price

						return sum
					}, 0)

					variation.groups = []
					variation.price -= totalPrice
				}

				this.updateModifiers(null, variation)
			},
			onKeydown (event) {
				if (event.key.toLowerCase() === 'e' || (!isNaN(event.key) && event.target.value.length > 3)) {
					event.preventDefault()
				}
			},
			kotCancellationCheck (variation, type, index) {
				if (['restaurant', 'qsr'].includes(this.merchant.businessType) && (this.bridgeName === 'ELECTRON' || this.appVersionNumber >= 3841)) {
					const selectedOrderItemIndex = this.selectedOrder ? this.filterItem(this.selectedOrder.items, variation, 'index') : -1

					if (this.selectedOrder && selectedOrderItemIndex >= 0) {
						const selectedOrderItem = this.selectedOrder.items[selectedOrderItemIndex]
						const cancellationIndex = this.kotCancellationReason.findIndex(item => item.orderItemCode === selectedOrderItem.item_code)

						if (cancellationIndex === -1 && ((type === '-' && selectedOrderItem.quantity >= variation.quantity) || (!type && selectedOrderItem.quantity > variation.quantity))) {
							window.kotCancellationModalCallback = this.kotCancellationModalCallback
							this.kotCancellationModal = {
								show: true,
								data: {
									item: selectedOrderItem,
									variation,
									index,
									type
								}
							}
						} else {
							this.onChangeQty(variation, type, index)
						}
					} else {
						this.onChangeQty(variation, type, index)
					}
				} else {
					this.onChangeQty(variation, type, index)
				}
			},
			kotCancellationModalCallback () {
				this.onChangeQty(
					this.kotCancellationModal.data.variation,
					this.kotCancellationModal.data.type,
					this.kotCancellationModal.data.index
				)
				this.kotCancellationModal = {
					show: false,
					data: {}
				}
			},
			addToFavorites (itemId, $event) {
				const favorites = this.favorites.slice()

				if (itemId) {
					if ($event) {
						favorites.push(itemId)
					} else {
						favorites.splice(favorites.indexOf(itemId), 1)
					}

					this.$store.commit('setState', {
						key: 'favorites',
						value: favorites,
						save: true
					})

					if (document.getElementById('favorites-tab').classList.contains('active')) {
						let items = favorites.length
							? this.$bridge.getItems(
								this.deviceId,
								this.locationId,
								this.objToJson({ id: favorites })
							)
							: {
								data: [],
								total: 0,
								offset: 0
							}

						items = typeof items === 'string' ? JSON.parse(items) : items
						this.$store.commit('setItems', items)
					}
				}
			},
			deleteItem () {
				if (this.cart.items.length) {
					return this.$swal({
						title: this.$t('actionCannotPerformed'),
						text: this.$t('clearCartItems'),
						icon: 'error',
						button: this.$t('ok')
					})
				}

				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						const date = new Date()
						let itemVariations = await this.$bridge.getItemVariations(
							this.deviceId, this.objToJson({
								item_id: this.selectedItem.id
							})
						)

						itemVariations = (typeof itemVariations === 'string' ? JSON.parse(itemVariations) : itemVariations).data
						await this.$bridge.updateMany('ItemVariation', itemVariations.map(iv => ({
							id: iv.id,
							is_active: false,
							updated_at: date
						})))
						await this.$bridge.insert('Item', {
							id: this.selectedItem.id,
							is_active: false,
							updated_at: date
						}, true)
						this.$emit('update:items')
						this.$refs.quantityModal.hide()
					}
				})
			},
			deleteItemVariation (variation) {
				this.$swal({
					title: this.$t('areYouSure'),
					text: this.$t('deleteRecordAlert'),
					icon: 'warning',
					buttons: [this.$t('no'), this.$t('yes')],
					dangerMode: true
				}).then(async (response) => {
					if (response) {
						this.$refs.quantityModal.hide()

						const cartItemIndex = this.filterItem(this.cart.items, variation, 'index')

						if (cartItemIndex !== -1) {
							const cartItem = JSON.parse(this.objToJson(this.cart.items))[cartItemIndex]

							this.$store.dispatch('modifyCart', {
								item: {
									id: cartItem.item_id,
									name: cartItem.item_name,
									category_id: cartItem.category_id,
									brand_id: cartItem.brand_id
								},
								variation: {
									...cartItem,
									quantity: 0
								}
							})
						}

						variation = {
							id: variation.id,
							is_active: false,
							updated_at: new Date()
						}
						await this.$bridge.insert(
							'ItemVariation',
							this.bridgeName === 'ANDROID' ? this.objToJson(variation) : variation,
							true
						)
						this.$emit('update:items')
					}
				})
			},
			resetModal () {
				this.removeSelectedItem()
			}
		}
	}
